:root {
    --color-black: #2A2922;
    --color-primary-lighter: #E29B6B;
    --color-primary-light: #D7793D;
    --color-primary-base: #CC5616;
    --color-primary-dark: #CC5616;
    --color-primary-darker: #CC5616;
}


/* Theme */
html, body {
    /* font-family: Montserrat, Arial, sans-serif; */
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: var(--color-black);
    letter-spacing: 0.1px;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

div, header, footer, section, ul {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    box-sizing: border-box;
    margin: 0;
    color: var(--color-primary-dark);
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    outline: none;
}

input, select, textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    outline: none;
    box-sizing: border-box;
}

table, tr, th {
    border-collapse: collapse;
}

dl, dd, dt {
    margin: 0;
}
